






























































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import Widget from "@/components/common/Widget.vue";
import DataTable from "@/components/table/DataTable.vue";
import api from "../../api";
import store from "../../store";
import { Watch } from "vue-property-decorator";
import { isEmailValid } from "@/helper/utils";
import { getAuthTypeText } from "@/auth";
import lodash from "lodash";
import SelectFilter from "@/components/common/SelectFilter.vue";

declare const $: any;

@Component({ components: { Breadcrumb, Widget, DataTable, SelectFilter } })
export default class UknowUsers extends Vue {
  tableColums = [
    {
      label: "ID",
      prop: "id",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "Vorname",
      prop: "firstname",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "Nachname",
      prop: "lastname",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "E-Mail Adresse",
      prop: "username",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "Level",
      prop: "level",
      bodyCellClass: "text-left",
      sortable: true,
      bodyCellStyle: "width:30px;",
    },
  ];
  perPageList = [25, 50, 100, 250];
  perPage = 25;
  orderby = "id";
  orderDirection = "asc";
  totalItems = 0;
  offsetPage = 0;
  tableRows: any[] = [];
  filteredRows: any[] = [];
  user = {
    firstname: "",
    lastname: "",
    username: "",
    level: 0,
    password: "",
    passwordGenerated: true,
  };
  userId = "";
  userToDelete: any = "";
  isFormValidate: any = "";
  isErrorUsername: boolean = false;
  isErrorEmail: boolean = false;

  levelLabel(user: any) {
    return user.level + " - u-know Admin";
  }
  mounted() {
    this.getUsers();
  }

  @Watch("user", { immediate: true, deep: true })
  onUserChange() {
    if (
      this.user.firstname == "" ||
      this.user.lastname == "" ||
      this.user.username == "" ||
      (this.user.password == "" && !this.user.passwordGenerated)
    ) {
      this.isFormValidate = true;
    } else {
      this.isFormValidate = false;
    }
  }

  changefilter(val: any, key: string) {
    this.offsetPage = 0;
    this.getUsers();
  }

  getUsers() {
    this.tableRows = [];
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    api
      .getUsers()
      .then((res: any) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        res.data.forEach((user: any) => {
          if (user[4] == 0) {
            this.tableRows.push({
              id: user[0],
              firstname: user[2],
              lastname: user[3],
              username: user[1],
              level: user[4],
              authTypeText: getAuthTypeText(user[5]),
            });
          }
        });
        this.filteredRows = this.tableRows;
        this.totalItems = this.filteredRows.length;
      })
      .catch((err) => {
        store.dispatch("updateLoading", store.state.loading - 1);
      });
  }

  addModal() {
    this.user = {
      firstname: "",
      lastname: "",
      username: "",
      password: "",
      level: 0,
      passwordGenerated: false,
    };
    this.userId = "";
    this.isErrorUsername = false;
    $("#add_modal").modal();
  }

  passwordGenerate() {
    this.user.password = "";
  }

  validateForm(data: {}) {}
  edit(event: any) {
    this.isErrorUsername = false;
    this.user = event;
    this.userId = event.id;
    $("#add_modal").modal();
  }

  onDeleteModal(event: any) {
    $("#delete_modal").modal();
    this.userToDelete = event.id;
  }
  deleteItem(event: any) {
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    api
      .deleteUser(this.userToDelete)
      .then((res) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        this.getUsers();
      })
      .catch((err) => {
        store.dispatch("updateLoading", store.state.loading - 1);
      });
  }

  add() {
    this.isErrorUsername = false;
    this.isErrorEmail = false;

    // Escap HTML and Script to prevent XSS
    this.user = {
      ...this.user,
      firstname: this.user.firstname,
      lastname: this.user.lastname,
      username: this.user.username,
    };

    if (isEmailValid(this.user.username)) {
      this.isErrorEmail = true;
      return;
    }

    if (this.userId) {
      this.tableRows.forEach((row) => {
        if (row.id === this.userId) {
          row = { ...this.user, id: row.id };
        }
      });

      // waiting for update API
      api
        .updateUser(this.userId, { ...this.user, enabled: true })
        .then((res) => {
          store.dispatch("updateLoading", store.state.loading - 1);
          if (this.user.passwordGenerated) {
            api.forgotPassword(this.user.username).then((res) => {});
          } else {
            store.dispatch("updateLoading", store.state.loading - 1);
          }
          this.getUsers();
          $("#add_modal").modal("hide");
        })
        .catch((err) => {
          this.isErrorUsername = true;
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    } else {
      store.dispatch(
        "updateLoading",
        store.state.loading >= 0 ? store.state.loading + 1 : 1
      );
      api
        .postUser(this.user)
        .then((res) => {
          store.dispatch("updateLoading", store.state.loading - 1);
          if (this.user.passwordGenerated) {
            api.setGeneratedPasswordUsers(res.data.id).then((res) => {
              store.dispatch("updateLoading", store.state.loading - 1);
            });
          } else {
            store.dispatch("updateLoading", store.state.loading - 1);
          }
          this.getUsers();
          $("#add_modal").modal("hide");
        })
        .catch((err) => {
          this.isErrorUsername = true;
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    }
  }

  search(event: any) {
    if (event) {
      this.filteredRows = this.tableRows.filter((row) => {
        return (
          row.firstname.toLowerCase().includes(event.toLowerCase()) ||
          row.lastname.toLowerCase().includes(event.toLowerCase()) ||
          row.id
            .toString()
            .toLowerCase()
            .includes(event.toString().toLowerCase()) ||
          row.level
            .toString()
            .toLowerCase()
            .includes(event.toString().toLowerCase()) ||
          row.username.toLowerCase().includes(event.toLowerCase())
        );
      });
    } else {
      this.filteredRows = this.tableRows;
    }
  }

  changeSortDirection(val: any) {
    this.orderDirection = val;
  }

  changeSortProperty(val: any) {
    this.orderby = val;
    this.orderDirection = "asc";
  }

  changePerPage(val: any): void {
    if (val) {
      let offsetPage = val - 1;
      if (offsetPage !== this.offsetPage) {
        this.offsetPage = offsetPage;
      }
    }
  }
}
